import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"
import { Container, Row, Col } from "react-bootstrap"
import Select from "react-select"
import i18n from "../../helpers/i18n"

import "../../scss/pages/collection.scss"

const urlHelper = require("../../helpers/url")
const filtersHelper = require("../../helpers/filters")
const filters = new filtersHelper()

const SelectFilter = ({ name, placeholder, options, onChange }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
    }),
  }

  return (
    <Select
      name={name}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      styles={customStyles}
    />
  )
}

const TextFilter = ({ name, placeholder, onChange }) => {
  const customStyles = {
    borderRadius: 0,
  }

  return (
    <input
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      className="form-control"
      style={customStyles}
    />
  )
}

const CollectionArtwork = ({ lang, artworks }) => {
  return artworks.map(artwork => {
    const artworkImageUrl = artwork.node.data.primary_image.localFile.childImageSharp.resize.src
    const artistUid = artwork.node.data.artist.uid
    const artistUrl = urlHelper(lang, "artist", artistUid)
    const artworkUrl = urlHelper(
      lang,
      "artwork",
      artistUid + "/" + artwork.node.uid
    )

    return (
      <Col key={artwork.node.uid} md="3">
        <a
          className="artwork"
          href={artworkUrl}
          title={artwork.node.data.title.text}
        >
          <img
            src={artworkImageUrl}
            alt={artwork.node.data.title.text}
          />
        </a>
        <a className="artist" href={artistUrl}>
          {artwork.node.data.artist.document.data.name.text}
        </a>
      </Col>
    )
  })
}

const Page = ({ pageContext, data: { allPrismicArtwork } }) => {
  const { lang } = pageContext
  i18n.setLanguage(lang)
  filters.allLabel = i18n.collectionFilterAll

  filters.setCollection(allPrismicArtwork.edges)
  const [artworks, setArtworks] = useState([...filters.collection])

  const onChangeText = function(e) {
    filters.apply(e.target.name, e.target.value)
    setArtworks([...filters.results])
  }

  const onChangeSelect = function(option, select) {
    filters.apply(select.name, option.value)
    setArtworks([...filters.results])
  }

  return (
    <Layout lang={lang}>
      <SEO title={i18n.collectionTitle} />
      <div className="collection-heading">
        <Container>
          <h1 className="gls-title">{i18n.collectionTitle}</h1>
        </Container>
        <Container>
          <Row>
            <Col md="3">
              <SelectFilter
                name="artist"
                placeholder={i18n.collectionArtistPlaceholder}
                options={filters.artists}
                onChange={onChangeSelect}
              />
            </Col>
            <Col md="3">
              <SelectFilter
                name="technique"
                placeholder={i18n.collectionTechniquePlaceholder}
                options={filters.techniques}
                onChange={onChangeSelect}
              />
            </Col>
            <Col md="3">
              <SelectFilter
                name="year"
                placeholder={i18n.collectionYearPlaceholder}
                options={filters.years}
                onChange={onChangeSelect}
              />
            </Col>
            <Col md="3">
              <TextFilter
                name="search"
                placeholder={i18n.collectionSearchPlaceholder}
                onChange={onChangeText}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="collection-artwork no-gutters">
          {artworks.length > 0 ? (
            <CollectionArtwork lang={lang} artworks={artworks} />
          ) : (
            <span className="no-results">{i18n.collectionEmptySelection}</span>
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ArtworksByLocale($lang: String!) {
    allPrismicArtwork(
      filter: { lang: { eq: $lang } }
      sort: { order: DESC, fields: data___price }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description {
              text
            }
            primary_image {
              localFile {
                childImageSharp {
                  resize(
                    fit: COVER
                    cropFocus: CENTER
                    width: 300
                    height: 300
                  ) {
                    src
                  }
                }
              }
            }
            artist {
              uid
              document {
                ... on PrismicArtist {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            technique {
              uid
              document {
                ... on PrismicTechnique {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            year
          }
        }
      }
    }
  }
`
